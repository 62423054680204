import Page from '@/components/page/index.jsx';
import { Button } from 'antd';

function Test() {
  const addNewText = () => {
    const client_key = 'awe8ff3xtnvhzw7g';
    const SERVER_ENDPOINT_REDIRECT = 'https://snaptrip.ai';
    const csrfState = Math.random().toString(36).substring(2);
    window.open(
      `https://www.tiktok.com/v2/auth/authorize?client_key=${client_key}&scope=user.info.basic&response_type=code&redirect_uri=${SERVER_ENDPOINT_REDIRECT}&state=${csrfState}`
    );
  };

  return (
    <Page className={'w-full flex flex-col items-center py-4 overflow-y-auto whitespace-pre'}>
      <Button className={'mb-3'} onClick={addNewText}>
        Add More Text
      </Button>
    </Page>
  );
}

export default Test;
